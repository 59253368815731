<template>
  <div class="page-wrapper bg-blue-dark-500 py-2">
    <div class="pt-5 p-3 text-center">
      <!-- <img src="../assets/logo.png" alt="logo" /> -->
      <img
        :src="$appLogo"
        alt="logo"
        style="height: 87px;object-fit: contain;"
      />
    </div>

    <b-row class="no-gutters justify-content-center my-5">
      <b-card
        no-body
        class="form-wrapper w-100 mx-3 border-0"
        :class="{ 'large-wrapper': isRegistration }"
      >
        <b-card-body class="p-4">
          <component
            :is="getActiveComponent"
            @registered="isRegistration = !isRegistration"
          />
        </b-card-body>
        <template v-slot:footer>
          <div v-if="!$is1Board" class="text-center card-footer">
            <b-link
              class="text-uppercase text-white b-link"
              @click="isRegistration = !isRegistration"
            >
              {{ $t(footerBtnLabel) }}
            </b-link>
          </div>
        </template>
      </b-card>
    </b-row>
    <h6 class="text-white text-center">v{{ appVersion }}</h6>
    <alert />
    <loader />
  </div>
</template>

<script>
import Alert from "../components/Base/BaseAlert";
import Loader from "../components/Base/BaseLoader";
import LoginForm from "./../components/User/LoginForm";
import RegistrationForm from "./../components/User/RegistrationForm";
import { appMixin } from "../mixins";
export default {
  mixins: [appMixin],
  components: {
    Loader,
    Alert,
    LoginForm,
    RegistrationForm
  },
  data() {
    return {
      isRegistration: false
    };
  },
  computed: {
    getActiveComponent() {
      return this.isRegistration ? "registration-form" : "login-form";
    },
    footerBtnLabel() {
      return this.isRegistration ? "button.backToLogin" : "register";
    },
    appVersion() {
      return this.$store.getters["info/getAppVersion"];
    }
  }
};
</script>

<style lang="scss" scoped>
.page-wrapper {
  min-height: calc(100vh);
}
.form-wrapper {
  max-width: 440px;
  min-height: 100px;
  transition: all 0.6s ease-in-out;
}
.large-wrapper {
  max-width: 800px;
  min-height: 500px;
}
.card-footer {
  border-top: unset;
  padding: 8px;
  background-color: var(--blue-dark-400);
  .b-link {
    opacity: 0.7;
  }
}
</style>

<template>
  <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
    <b-form @submit.stop.prevent="handleSubmit(createUser)">
      <h3 class="text-center text-primary font-weight-normal">
        {{ $t("registration") }}
      </h3>
      <b-row class="no-gutters mt-4 ml-n2">
        <b-col class="col-12 mx-0 pr-0 pl-2">
          <form-select
            v-model="user.type"
            class="select-input-field"
            veeName="user"
            :veeRules="{ required: true, select: true }"
          >
            <b-form-select-option :value="null" disabled>
              {{ $t("selectAnOption") }}
            </b-form-select-option>
            <b-form-select-option
              v-for="(type, index) in userTypeOptions"
              :key="index"
              :value="type"
            >
              {{ $t(type) }}
            </b-form-select-option>
          </form-select>
        </b-col>
        <b-col class="col-12 pl-2">
          <form-input
            v-model="user.email"
            class="input-field"
            :placeholder="`${$t('email')}*`"
            veeName="email"
            :veeRules="{ required: true, email: true }"
            @input="user.email = user.email.toLowerCase()"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="user.name"
            class="input-field"
            :placeholder="`${$t('name')}*`"
            veeName="name"
            :veeRules="{ required: true }"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="user.surname"
            class="input-field"
            :placeholder="`${$t('surname')}*`"
            veeName="surname"
            :veeRules="{ required: true }"
          />
        </b-col>
      </b-row>
      <b-row class="no-gutters mt-4 ml-n2">
        <b-col class="col-12 pl-2">
          <form-input
            v-model="user.legalName"
            class="input-field"
            :placeholder="`${$t('legalName')}*`"
            veeName="legalName"
            :veeRules="{ required: true }"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="user.mobile"
            class="input-field"
            :placeholder="`${$t('mobile')}*`"
            veeName="mobile"
            :veeRules="{ required: true }"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="user.landline"
            class="input-field"
            :placeholder="$t('landline')"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="user.vat"
            class="input-field"
            :placeholder="`${$t('vat')}*`"
            veeName="vat"
            :veeRules="{ required: true }"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="user.companyEmail"
            class="input-field"
            :placeholder="$t('companyEmail')"
            veeName="companyEmail"
            :veeRules="{ email: true }"
            @input="user.companyEmail = user.companyEmail.toLowerCase()"
          />
        </b-col>
        <b-col class="col-12 pl-2" :class="{ 'col-sm-9': hasAddressInfo }">
          <google-place-search
            v-model="searchAddress"
            class="input-field"
            :placeholder="`${$t('address')}*`"
            veeName="address"
            :address="user.address"
            :veeRules="{ required: true }"
            @setPlaceInfo="setPlaceInfo"
            @reset="resetAddressDetails"
          />
        </b-col>
        <b-col v-if="hasAddressInfo" class="col-12 col-sm-3 pl-2">
          <form-input
            v-model="user.streetNumber"
            class="input-field"
            :placeholder="`${$t('streetNumber')}*`"
            veeName="streetNumber"
            :veeRules="{ required: true }"
          />
        </b-col>
        <b-row v-if="hasAddressInfo" class="no-gutters ml-m2 w-100">
          <b-col class="col-12 col-sm-3 pl-2">
            <form-input
              v-model="user.zip"
              class="input-field"
              :placeholder="`${$t('cap')}*`"
              veeName="cap"
              :veeRules="{ required: true }"
            />
          </b-col>
          <b-col class="col-12 col-sm-6 pl-2">
            <form-input
              v-model="user.city"
              class="input-field"
              :placeholder="`${$t('city')}*`"
              veeName="city"
              :veeRules="{ required: true }"
            />
          </b-col>
          <b-col class="col-12 col-sm-3 pl-2">
            <form-input
              v-model="user.province"
              class="input-field"
              :placeholder="`${$t('province')}*`"
              veeName="province"
              :veeRules="{ required: true }"
            />
          </b-col>
        </b-row>
      </b-row>
      <b-row v-if="user.type === 'administrator'" class="no-gutters mt-4 ml-n2">
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="administrator.fiscalCode"
            class="input-field"
            :placeholder="`${$t('fiscalCode')}*`"
            veeName="fiscalCode"
            :veeRules="{ required: true }"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="administrator.website"
            class="input-field"
            :placeholder="$t('website')"
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="administrator.legalEmail"
            class="input-field"
            :placeholder="$t('legalEmail')"
            veeName="legalEmail"
            :veeRules="{ email: true }"
            @input="
              administrator.legalEmail = administrator.legalEmail.toLowerCase()
            "
          />
        </b-col>
        <b-col class="col-12 col-sm-6 pl-2">
          <form-input
            v-model="administrator.fax"
            class="input-field"
            :placeholder="$t('fax')"
          />
        </b-col>
      </b-row>
      <b-row
        v-else-if="user.type === 'maintainer'"
        class="no-gutters mt-4 ml-n2"
      >
        <b-col class="col-12 pl-2">
          <b-form-group
            :label="$t('page.registration.selectMaintainerSkills')"
            label-class="font-weight-bold text-secondary"
          >
            <b-form-checkbox-group
              v-model="maintainer.maintainerType"
              class="checkbox-group"
            >
              <b-row class="no-gutters justify-content-between">
                <b-col
                  class="col-4"
                  v-for="(type, index) in maintainerTypeOptions"
                  :key="index"
                >
                  <b-form-checkbox :value="type">
                    {{ $t(`fixCategories.${type}`) }}
                  </b-form-checkbox>
                </b-col>
              </b-row>
            </b-form-checkbox-group>
          </b-form-group>
        </b-col>
      </b-row>
      <div class="w-100 mt-5">
        <vue-recaptcha
          ref="recaptcha"
          :sitekey="recaptchaSitekey"
          :loadRecaptchaScript="true"
          :language="locale"
          @verify="onVerifyRecaptcha"
          @expired="onExpiredRecaptcha"
          @error="onErrorRecaptcha"
        ></vue-recaptcha>
      </div>
      <b-row class="no-gutters justify-content-end pt-3">
        <b-col class="col-12 col-sm-auto">
          <b-button
            variant="primary"
            class="text-white text-uppercase"
            :disabled="invalid || !isFormValid"
            block
            type="submit"
          >
            {{ $t("button.send") }}
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>

<script>
import { GET_THANK_PAGE, MAINTAINER_TYPES } from "../../Utils";
import ConfigProvider from "./../../plugins/config-provider";

import VueRecaptcha from "vue-recaptcha";

import FormInput from "../Base/BaseFormInput";
import FormSelect from "../Base/BaseFormSelect";
import GooglePlaceSearch from "./../Google/GooglePlaceSearch";
export default {
  components: {
    VueRecaptcha,
    FormInput,
    FormSelect,
    GooglePlaceSearch
  },
  data() {
    return {
      user: {
        name: "",
        surname: "",
        email: "",
        type: "maintainer",
        legalName: "",
        mobile: "",
        landline: "",
        companyEmail: "",
        vat: "",
        address: "",
        zip: "",
        province: "",
        city: "",
        streetNumber: "",
        country: "",
        latitude: 0,
        longitude: 0,
        placeId: ""
      },
      administrator: {
        fiscalCode: "",
        website: "",
        legalEmail: "",
        fax: ""
      },
      maintainer: {
        maintainerType: [],
        other: ""
      },
      userTypeOptions: ["maintainer", "administrator"],
      searchAddress: "",
      isRecaptchaVerified: false
    };
  },
  computed: {
    recaptchaSitekey() {
      return ConfigProvider.value("recaptchaSitekey");
    },
    locale() {
      return this.$i18n.locale;
    },
    hasAddressInfo() {
      return this.user.address.length > 0;
    },
    maintainerTypeOptions() {
      return MAINTAINER_TYPES.slice(0, 12);
    },
    isFormValid() {
      return (
        this.isRecaptchaVerified &&
        (this.user.type === "maintainer"
          ? this.maintainer.maintainerType.length > 0
          : true)
      );
    }
  },
  methods: {
    onVerifyRecaptcha() {
      this.isRecaptchaVerified = true;
    },
    onExpiredRecaptcha() {
      this.isRecaptchaVerified = false;
    },
    onErrorRecaptcha() {
      console.log("Error in recaptcha");
    },
    async createUser() {
      try {
        this.$store.commit("loader/SET_LOADER", {});
        await this.$store.dispatch("user/createUser", {
          userInfo: this.getUserInfo()
        });
        if (this.user.type === "maintainer") {
          this.$emit("registered");
          this.$store.commit("alert/SET_ALERT", {
            type: "success",
            messageText: "page.registration.registrationLinkWarning",
            messageParams: {
              mail: this.user.email
            }
          });
        } else {
          location.replace(GET_THANK_PAGE(this.user.type));
        }
      } catch (error) {
        if (error.errorCode === 616)
          this.$router.push({
            name: "TokenRecovery",
            params: { email: this.getUserInfo().email }
          });
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getUserInfo() {
      let userInfo = null;
      if (this.user.type === "administrator") userInfo = this.administrator;
      else if (this.user.type === "maintainer") userInfo = this.maintainer;

      return { ...this.user, ...userInfo };
    },
    resetAddressDetails() {
      this.user.address = "";
      this.user.zip = "";
      this.user.province = "";
      this.user.city = "";
      this.user.streetNumber = "";
      this.user.country = "";
      this.user.latitude = 0;
      this.user.longitude = 0;
      this.user.placeId = "";
    },
    setPlaceInfo(place) {
      this.user.address = place.streetName;
      this.searchAddress = this.user.address;

      this.user.zip = place.postalCode;
      this.user.province = place.province;
      this.user.city = place.city;
      this.user.streetNumber = place.streetNumber;
      this.user.country = place.country;
      this.user.latitude = parseFloat(place.lat);
      this.user.longitude = parseFloat(place.lng);
      this.user.placeId = place.placeId;
    }
  }
};
</script>

<style lang="scss" scoped>
.input-field {
  /deep/ {
    .form-control {
      background-color: var(--gray-light);
    }
    .input-group-text {
      background-color: var(--gray-light);
    }
  }
}
.select-input-field {
  /deep/ {
    .custom-select {
      background-color: var(--gray-light);
      option:not([disabled]) {
        background-color: white;
      }
    }
  }
}
.checkbox-group {
  padding: 10px;
  border-radius: 0.25rem;
  background-color: var(--gray-light);
}
</style>
